<template>
  <el-select
    :disabled="disabled"
    :value="value"
    class="form-item"
    placeholder="Select"
    @change="(e) => $emit('change', e)"
  >
    <el-option
      v-for="item in statusOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
/**
 * property :{String:Number} value (v-model) 当前选中的值
 * property :{Boolean} disabled 是否禁用
 *
 */
import { memberType } from "@/lib/data/tableData";
export default {
  name: "SelectStatus",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: { type: [String, Number] },
    disabled: { type: Boolean, disabled: false },
  },
  data() {
    return {
      statusOptions: memberType,
    };
  },
};
</script>

<style scoped lang="scss"></style>
